import React from 'react'
import image1 from "../../../assets/images/innovation 1.png"
import image2 from "../../../assets/images/experience 1.png"
import image3 from "../../../assets/images/success 1.png"
import image4 from "../../../assets/images/service (1) 1.png"
import image5 from "../../../assets/images/develop (1) 1.png"

const CardMakeSection = () => {
    const data = [
        {
            image: image1,
            title: "Innovative Gameplay",
            detail: "We bring fresh and exciting ideas to the gaming world, crafting games with unique mechanics and engaging challenges that keep players coming back."
        },
        {
            image: image2,
            title: "Immersive Experience",
            detail: "Our games are designed to fully immerse players in captivating worlds, with rich graphics, and interactive elements that enhance the gaming experience."
        },
        {
            image: image3,
            title: "Proven Success",
            detail: "We bring fresh and exciting ideas to the gaming world, crafting games with unique mechanics and engaging challenges that keep players coming back."
        },
        {
            image: image4,
            title: "Quality Assurance",
            detail: "We are committed to delivering games that are polished, bug-free, and optimized for the best possible performance on all devices."
        },
        {
            image: image5,
            title: "Player Centered Approach",
            detail: "Our focus is always on the player. We actively listen to feedback and continuously update our games to ensure they meet the expectations and desires of our gaming community."
        }
    ]

    return (
        <div className='flex flex-wrap justify-center gap-10'>
            {
                data.map((i, index) => {
                    return (
                        <div key={index} className='bg-gradient-to-b from-[#072541] to-[#040B20] w-[369px] h-[445px] flex flex-col justify-center items-center rounded-3xl border border-[#333D68]'>
                            <img src={i.image} className='w-[148px] h-[148px] mb-5' alt={i.title} />
                            <div className='px-6 text-start flex flex-col gap-5'>
                                <h1 className='ibm-plex-sans-normal text-[26px]'>{i.title}</h1>
                                <h3 className='ibm-plex-sans-normal text-[16px]'>{i.detail}</h3>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CardMakeSection
