import React from 'react'
import CardMakeSection from './CardMakeSection'

const MakeSection = () => {
  return (
    <div className='mt-5 mx-20 '>
    <h1 className='text-[54px] ibm-plex-serif-light mb-20 '>What Makes Us Different?</h1>
      <CardMakeSection/>
    <hr className='bg-[#777485] h-[1px] mt-[80px]'></hr>

</div>
  )
}

export default MakeSection