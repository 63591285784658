import React from 'react'
import CardAppSection from './CardAppSection'

const AppSection = () => {
  return (
    <div className='mt-5 mx-20'>
        <h1 className='text-[54px] ibm-plex-serif-light mb-28 '>Some Of Our Apps</h1>
        <CardAppSection/>
        <hr className='bg-[#777485] h-[1px] mt-[80px]'></hr>

    </div>
  )
}

export default AppSection