import React from 'react'
import Game1 from "../../../assets/images/Game 1 1.png"
import Game2 from "../../../assets/images/game 2 1 (1).png"
import Game3 from "../../../assets/images/Game 3 1.png"
import Game4 from "../../../assets/images/Game 4 1.png"
import fireIcon from "../../../assets/images/trending-topic 1.png"
const CardAppSection = () => {
    const cardDetail=[
        {
            image:Game1,
            detail:"10k+ Downloads"
        },
        {
            image:Game2,
            detail:"10k+ Downloads"
        },
        {
            image:Game3,
            detail:"20k+ Downloads"
        },
        {
            image:Game4,
            detail:"20k+ Downloads"
        }
    ]
  return (
    <div className='flex gap-14 justify-center'>
        {
            cardDetail.map((i)=>{
return <div className='w-[300px] h-[375px] bg-[#041026] rounded-[25px]'>
        <img src={i.image} className='w-[300px] h-[310px]'/>
        <div className='bg-[#041026] flex justify-center pt-4 gap-4 ibm-plex-sans-medium text-[20px]'>
            <img src={fireIcon}/>
            <div>{i.detail}</div>
            </div>

</div>
            })
        }
    
    </div>
  )
}

export default CardAppSection