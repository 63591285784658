import React,{useState} from 'react'
import AboutSection from './sections/AboutSection'
import AppSection from './sections/AppSection'
import MakeSection from './sections/MakeSection'
import ContactSection from './sections/ContactSection'
import Header from '../Header'

const Home = () => {
  const [activeMenu, setActiveMenu] = useState("home");
  return (
    <div className='bg-[#073052] text-center'>
               <Header activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
      <div className=' text-white'>
      <AboutSection setActiveMenu={setActiveMenu}/>
      <section id='our-apps'>
      <AppSection/>
      </section>
<section id='expertise'>
<MakeSection/>
</section>
<section id='contact'>
<ContactSection/>
</section>

      </div>

       
    </div>
  )
}

export default Home