import React, { useState } from 'react';
import logo from "../assets/images/logo 1.jpg";
import arrow from "../assets/images/—Pngtree—white direction arrow right icon_4539096.png";

const Header = ({activeMenu,setActiveMenu}) => {
  

    const menu = [
        { name: "Home", targetId: "home" },
        { name: "About Us", targetId: "about-us" },
        { name: "Our Apps", targetId: "our-apps" },
        { name: "Expertise", targetId: "expertise" },
    ];

    const handleScrollToSection = (targetId) => {
        const section = document.getElementById(targetId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setActiveMenu(targetId); 
        }
    };

    return (
        <div className='bg-white h-[86px] flex justify-around text-black  w-full'>
            <div className='flex justify-between gap-4 mr-[70px]'>
                <img src={logo} alt="game" className="w-[32px] h-[33px] mt-[27px]" />
                <h1 className='font-extrabold text-[30px] inter-extraBold mt-[20px]'>Innovapps</h1>
            </div>
            {/* <div className='flex h-full'> */}
                <div className="w-px mt-[29px] bg-[#7D7D7D] h-[28px]"></div>
            
            
            <div className='flex justify-between mx-[70px] space-x-20 ibm-plex-sans-normal text-[20px] text-[#161616]'>
                {menu.map((item) => (
                    <h1
                        key={item.targetId}
                        className={`mt-[29px] cursor-pointer ${activeMenu === item.targetId ? 'font-semibold underline underline-offset-8' : ''}`}
                        onClick={() => handleScrollToSection(item.targetId)}
                    >
                        {item.name}
                    </h1>
                ))}
            </div>

            <div className="w-px mt-[29px] bg-[#7D7D7D] h-[29px] mr-[70px]"></div>
            <button
                className='flex items-center justify-center gap-2 mt-[18px] bg-[#1174C1] text-[#FFFFFF] w-[156px] h-[51px]'
                onClick={() => handleScrollToSection("contact")}
            >
                <span>Contact Us</span>
                <img src={arrow} alt="arrow" className='w-[17px] mt-[4px]'/>
            </button>
        </div>
    );
}

export default Header;
